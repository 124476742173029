import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function productInfo({id}) {
  return omniFetch({
    url: config.v1Path + config.productInfoPath + "/" + id,
    method: "get",
    params: {}
  })
}

export function productReviewList({id, pageIndex, pageSize, onlyImage}) {
  return omniFetch({
    url: config.v1Path + config.productReviewListPath + "/" + id,
    method: "post",
    params: {pageIndex, pageSize, onlyImage}
  })
}

export function productReviewRecommendList({id, pageIndex, pageSize}) {
  return omniFetch({
    url: config.v1Path + config.productReviewRecommendListPath + "/" + id,
    method: "post",
    params: {pageIndex, pageSize}
  })
}

export function productSkuList({shopProductId, sku}) {
  return omniFetch({
    url: config.v1Path + config.productSkuListPath,
    method: "post",
    params: {shopProductId, sku}
  })
}

export function productReviewAdd({
                                   orderId,
                                   shopProductId,
                                   sku,
                                   unitNo,

                                   subject,
                                   score,
                                   title,
                                   description,
                                   fileUrls
}) {
  return omniFetch({
    url: config.v1Path + config.productReviewAddPath,
    method: "post",
    params: {
      orderId,
      shopProductId,
      sku,
      unitNo,

      subject,
      score,
      title,
      description,
      fileUrls
    }
  })
}


export function productReviewInfo({shopProductId, sku}) {
  return omniFetch({
    url: config.v1Path + config.productReviewInfoPath,
    method: "post",
    params: {shopProductId, sku}
  })
}
