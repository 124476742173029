import {omniFetch} from "~/utils/fetch";
import {config} from "~/utils/config";

export function wishlistList({pageIndex, pageSize}) {
  return omniFetch({
    url: config.v1Path + config.wishlistListPath,
    method: "get",
    params: {pageIndex, pageSize}
  })
}

export function wishlistAdd({shopProductId}) {
  return omniFetch({
    url: config.v1Path + config.wishlistAddPath,
    method: "post",
    params: {shopProductId}
  })
}

export function wishlistDel({shopProductId}) {
  return omniFetch({
    url: config.v1Path + config.wishlistDelPath,
    method: "post",
    params: {shopProductId}
  })
}

export function wishlistBrandList({pageIndex, pageSize}) {
  return omniFetch({
    url: config.v1Path + config.wishlistBrandListPath,
    method: "get",
    params: {pageIndex, pageSize}
  })
}

export function wishlistBrandAdd({shopBrandId}) {
  return omniFetch({
    url: config.v1Path + config.wishlistBrandAddPath,
    method: "post",
    params: {shopBrandId}
  })
}

export function wishlistBrandDel({shopBrandId}) {
  return omniFetch({
    url: config.v1Path + config.wishlistBrandDelPath,
    method: "post",
    params: {shopBrandId}
  })
}
