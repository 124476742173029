<script setup>
import {useBaseStore} from "~/stores/base";
/** 这里用 URL_PRODUCT URL_REVIEW_CREATE 是因为 用 PATH 会使从 BNTO detail进入的商品页，会无法滚动 **/
import {BNTO_STATUS, PATH_COLLECTION_ALL_STYLES, URL_PRODUCT, SKU_STATUS, URL_REVIEW_CREATE} from "~/utils/constants";
import _ from "lodash"
import ModuleSizeSelector from "~/components/DrawerCart/ModuleSizeSelector.vue";
import {productSkuList} from "~/api/api.product";
import {cartEdit, cartRemoveSku} from "~/api/api.cart";
import {wishlistAdd, wishlistDel} from "~/api/api.wishlist";
import {tiktokPixelTrack} from "~/utils/tiktok-track";
import {toLogin} from "~/utils/common";

const BNTOMessage = useNuxtApp().$BNTOMessage
const router = useRouter()
const debounce = _.debounce((params, func) => {
  func(params);
}, 300);

/** emits **/
const emits = defineEmits(["remove", "buy"])

/** props **/
const props = defineProps({
  orderId: {
    default: "",
    required: true
  },
  product: {
    default: {},
    required: true
  },
  bntoStatus: {
    default: "",
    required: true
  }
})

const sizeOptions = ref([])

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const isLogin = computed(() => useBaseStore().getterIsLogin)

/** methods **/
const handleAdd = () => {
  router.push(PATH_COLLECTION_ALL_STYLES)
}
const handleSelectorOpen = async () => {
  sizeOptions.value = []
  const res = await productSkuList({
    shopProductId: props.product.productId,
    sku: props.product.sku
  })
  console.log("productSkuList", res.result)
  sizeOptions.value = res.result.sizeList
}
const handleSelect = async (option) => {
  console.log("option", option)
  try {
    const newSku = option.sku
    const res = await cartEdit({sku: newSku, cartItemId: props.product.cartItemId})
    console.log("cartEdit", res.result)

    // 更新cartItemList
    props.product.sku = newSku
    props.product.size = option.value
    props.product.skuStatus = SKU_STATUS.NORMAL
  } catch (e) {
  }
}
const handleRemove = async () => {
  await cartRemoveSku({sku: props.product.sku})
  emits("remove", {cartItemId: props.product.cartItemId})
}
const handleBuy = async () => {
  emits("buy", {product: props.product})
}
const handleLike = async () => {
  if (!isLogin.value) return toLogin()
  props.product.liked = !props.product.liked
  debounce({}, async () => {
    const productId = props.product.productId
    if (props.product.liked) {
      await wishlistAdd({shopProductId: productId})
      tiktokPixelTrack("AddToWishlist", {content_id: productId})
    } else {
      await wishlistDel({shopProductId: productId})
    }
  })
}
</script>

<template>
  <div class="module-cart-item-mobile" v-if="isMobile">
    <!-- 空 -->
    <div @click="handleAdd" class="empty" v-if="_.isEmpty(product)">
      <img src="@/assets/img/icon-plus-black.svg" alt="plus">
    </div>

    <!-- 有 -->
    <div
      v-else
      class="item"
      :class="{alert: product.skuStatus === SKU_STATUS.ISSUE_WITH_RETURN || product.skuStatus === SKU_STATUS.OUT_OF_STOCK}"
    >
      <div
        class="tag"
        :class="{
          tag_success: product.skuStatus === SKU_STATUS.PURCHASED,
          tag_alert: product.skuStatus === SKU_STATUS.ISSUE_WITH_RETURN || product.skuStatus === SKU_STATUS.OUT_OF_STOCK
        }"
      >
        <span v-if="product.skuStatus === SKU_STATUS.PURCHASED">Purchased</span>
        <span v-if="product.skuStatus === SKU_STATUS.ISSUE_WITH_RETURN">Issue with Return</span>
        <span v-if="product.skuStatus === SKU_STATUS.OUT_OF_STOCK">Out of Stock</span>
      </div>
      <div class="image-container">
        <nuxt-link
          :to="`${URL_PRODUCT}/${product.productId}/${product.handle}`"
          class="product-img"
          :style="{backgroundImage: `url(${product.productImages[0].url})`}"
        >
          <div class="cover" v-if="product.skuStatus === SKU_STATUS.OUT_OF_STOCK"/>
        </nuxt-link>

        <div class="like" @click.stop="handleLike">
          <img v-if="product.liked" src="@/assets/img/icon-liked.svg" alt="icon">
          <img v-else src="@/assets/img/icon-unlike.svg" alt="icon">
        </div>
      </div>
      <div class="info">
        <nuxt-link :to="`${URL_PRODUCT}/${product.productId}/${product.handle}`" class="brand">
          {{ product.brand }}
        </nuxt-link>
        <nuxt-link :to="`${URL_PRODUCT}/${product.productId}/${product.handle}`" class="productName">
          {{ product.productName }}
        </nuxt-link>
        <nuxt-link :to="`${URL_PRODUCT}/${product.productId}/${product.handle}`" class="price">
          <span v-if="product.discount">
            <span class="bold">${{ product.salePrice }}</span>
            <span class="delete">${{ product.originalPrice }}</span>
            <span class="discount">({{ product.discount }}% off)</span>
          </span>
          <span v-else class="original">Retail: ${{ product.originalPrice }}</span>
          <img v-if="product.premium" class="premium" src="@/assets/img/icon-diamond.svg" alt="diamond">
        </nuxt-link>
        <nuxt-link
          class="size"
          :to="`${URL_PRODUCT}/${product.productId}/${product.handle}`"
          v-if="bntoStatus !== BNTO_STATUS.OPEN && bntoStatus !== BNTO_STATUS.FULL"
        >
          Size: {{ product.size }}
        </nuxt-link>
        <module-size-selector
          v-if="bntoStatus === BNTO_STATUS.OPEN || bntoStatus === BNTO_STATUS.FULL"
          :product-id="product.productId"
          :current-sku-status="product.skuStatus"
          :current-size="product.size"
          :options="sizeOptions"
          @open="handleSelectorOpen"
          @close="() => {}"
          @select="handleSelect"
        />
      </div>
      <div class="btn-container">
        <!-- 删除按钮 -->
        <div
          v-if="product.btnList.find(btn => btn.type === 'REMOVE')"
          class="btn"
          @click="handleRemove"
        >
          <span>remove</span>
        </div>
        <!-- 购买按钮 -->
        <div
          v-if="product.btnList.find(btn => btn.type === 'BUY')"
          class="btn btn-buy"
          @click="handleBuy"
        >
          <span>buy</span>
        </div>
        <!-- 评论按钮 -->
        <nuxt-link
          :to="`${URL_REVIEW_CREATE}?orderId=${orderId}&productId=${product.productId}&sku=${product.sku}&unitNo=${product.unitNo}`"
          v-if="product.btnList.find(btn => btn.type === 'REVIEW')"
          class="btn"
        >
          <span>review</span>
        </nuxt-link>
        <!-- 评分按钮 -->
        <nuxt-link
          :to="`${URL_PRODUCT}/${product.productId}/${product.handle}#reviews-all`"
          v-if="product.btnList.find(btn => btn.type === 'STAR')"
          class="btn"
        >
          <span>{{ product.btnList.find(btn => btn.type === 'STAR').desc }}</span>
          <img src="@/assets/img/icon-star-filled.svg" alt="star">
        </nuxt-link>
      </div>
    </div>
  </div>
  <div class="module-cart-item-desktop" v-else>
    <!-- 空 -->
    <div @click="handleAdd" class="empty" v-if="_.isEmpty(product)">
      <img src="@/assets/img/icon-plus-black.svg" alt="plus">
    </div>

    <!-- 有 -->
    <div
      v-else
      class="item"
      :class="{alert: product.skuStatus === SKU_STATUS.ISSUE_WITH_RETURN || product.skuStatus === SKU_STATUS.OUT_OF_STOCK}"
    >
      <div
        class="tag"
        :class="{
          tag_success: product.skuStatus === SKU_STATUS.PURCHASED,
          tag_alert: product.skuStatus === SKU_STATUS.ISSUE_WITH_RETURN || product.skuStatus === SKU_STATUS.OUT_OF_STOCK
        }"
      >
        <span v-if="product.skuStatus === SKU_STATUS.PURCHASED">Purchased</span>
        <span v-if="product.skuStatus === SKU_STATUS.ISSUE_WITH_RETURN">Issue with Return</span>
        <span v-if="product.skuStatus === SKU_STATUS.OUT_OF_STOCK">Out of Stock</span>
      </div>
      <div class="image-container">
        <nuxt-link
          :to="`${URL_PRODUCT}/${product.productId}/${product.handle}`"
          class="product-img"
          :style="{backgroundImage: `url(${product.productImages[0].url})`}"
        >
          <div class="cover" v-if="product.skuStatus === SKU_STATUS.OUT_OF_STOCK"/>
        </nuxt-link>

        <div class="like" @click.stop="handleLike">
          <img v-if="product.liked" src="@/assets/img/icon-liked.svg" alt="icon">
          <img v-else src="@/assets/img/icon-unlike.svg" alt="icon">
        </div>
      </div>
      <div class="info">
        <nuxt-link :to="`${URL_PRODUCT}/${product.productId}/${product.handle}`" class="brand">
          {{ product.brand }}
        </nuxt-link>
        <nuxt-link :to="`${URL_PRODUCT}/${product.productId}/${product.handle}`" class="productName">
          {{ product.productName }}
        </nuxt-link>
        <nuxt-link :to="`${URL_PRODUCT}/${product.productId}/${product.handle}`" class="price">
          <span v-if="product.discount">
            <span class="bold">${{ product.salePrice }}</span>
            <span class="delete">${{ product.originalPrice }}</span>
            <span class="discount">({{ product.discount }}% off)</span>
          </span>
          <span v-else class="original">Retail: ${{ product.originalPrice }}</span>
          <img v-if="product.premium" class="premium" src="@/assets/img/icon-diamond.svg" alt="diamond">
        </nuxt-link>
        <nuxt-link :to="`${URL_PRODUCT}/${product.productId}/${product.handle}`" class="size" v-if="bntoStatus !== BNTO_STATUS.OPEN && bntoStatus !== BNTO_STATUS.FULL">
          Size: {{ product.size }}
        </nuxt-link>
        <module-size-selector
          v-if="bntoStatus === BNTO_STATUS.OPEN || bntoStatus === BNTO_STATUS.FULL"
          :product-id="product.productId"
          :current-sku-status="product.skuStatus"
          :current-size="product.size"
          :options="sizeOptions"
          @open="handleSelectorOpen"
          @close="() => {}"
          @select="handleSelect"
        />
      </div>
      <div class="btn-container">
        <!-- 删除按钮 -->
        <div
          v-if="product.btnList.find(btn => btn.type === 'REMOVE')"
          class="btn"
          @click="handleRemove"
        >
          <span>remove</span>
        </div>
        <!-- 购买按钮 -->
        <div
          v-if="product.btnList.find(btn => btn.type === 'BUY')"
          class="btn btn-buy"
          @click="handleBuy"
        >
          <span>buy</span>
        </div>
        <!-- 评论按钮 -->
        <nuxt-link
          :to="`${URL_REVIEW_CREATE}?orderId=${orderId}&productId=${product.productId}&sku=${product.sku}&unitNo=${product.unitNo}`"
          v-if="product.btnList.find(btn => btn.type === 'REVIEW')"
          class="btn"
        >
          <span>review</span>
        </nuxt-link>
        <!-- 评分按钮 -->
        <nuxt-link
          :to="`${URL_PRODUCT}/${product.productId}/${product.handle}#reviews-all`"
          v-if="product.btnList.find(btn => btn.type === 'STAR')"
          class="btn"
        >
          <span>{{ product.btnList.find(btn => btn.type === 'STAR').desc }}</span>
          <img src="@/assets/img/icon-star-filled.svg" alt="star">
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-cart-item-mobile {
  width: 160px;

  .empty {
    width: 100%;
    height: 368px;
    border: 1px dashed $color-gray-300;
    background: $color-gray-50;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  .item {
    width: 100%;
    position: relative;

    .tag {
      width: fit-content;
      position: absolute;
      z-index: 1;
      left: 2px;
      top: 2px;

      display: flex;
      padding: 3px 6px;
      justify-content: center;
      align-items: center;
      gap: 3px;

      text-align: center;
      /* Text xs/Regular */
      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */
    }

    .tag_success {
      background-color: $color-success-50;
      color: $color-BNTO-green;
    }

    .tag_alert {
      background-color: $color-error-100;
      color: $color-BNTO-red-alert;
    }

    .image-container {
      border-top: 0.5px solid $color-gray-300;
      border-left: 0.5px solid $color-gray-300;
      border-right: 0.5px solid $color-gray-300;

      width: 100%;
      height: 224px;
      display: flex;

      position: relative;

      .product-img {
        width: 100%;
        height: 100%;
        background: center center no-repeat;
        background-size: cover;

        .cover {
          width: 100%;
          height: 100%;
          background-color: white;
          opacity: 0.5;
        }
      }

      .like {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        position: absolute;
        right: 2px;
        top: 2px;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .info {
      border-left: 0.5px solid $color-gray-300;
      border-right: 0.5px solid $color-gray-300;
      border-bottom: 0.5px solid $color-gray-300;
      padding: 8px;

      .brand {
        overflow: hidden;
        text-overflow: ellipsis;
        /* Text xs/Bold */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 150% */

        // 单行
        word-break: break-all;
        word-wrap: anywhere;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        -webkit-line-clamp: 1;
      }

      .productName {
        overflow: hidden;
        text-overflow: ellipsis;
        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 150% */

        // 单行
        word-break: break-all;
        word-wrap: anywhere;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        -webkit-line-clamp: 1;
      }

      .price {
        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 150% */

        display: flex;
        align-items: center;
        gap: 2px;

        .bold {
          /* Text xs/Bold */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 650;
          line-height: 18px; /* 150% */
        }

        .delete {
          color: $color-gray-500;
          text-decoration: line-through;
          margin-left: 4px;
        }

        .discount {
          /* Text xs/Bold */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 650;
          line-height: 18px; /* 150% */

          color: $color-BNTO-green;
          margin-left: 4px;
        }

        .original {
        }

        .premium {
          width: 18px;
          height: 18px;
          flex-shrink: 0;
        }
      }

      .size {
        overflow: hidden;
        text-overflow: ellipsis;
        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 150% */
      }
    }

    .btn-container {
      display: flex;

      .btn {
        width: 100%;
        height: 30px;
        border: 0.5px solid $color-BNTO-beige-dark;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        /* Button/small */
        font-family: "Druk Wide Cy";
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px; /* 111.111% */
        text-transform: uppercase;

        cursor: pointer;

        span {
          padding-top: 2px;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }

      .btn-buy {
        background: $color-BNTO-beige-light;
      }
    }
  }

  .alert {
    // 0.5px border
    box-shadow: 0 0 0 0.5px $color-BNTO-red-alert;
  }
}

.module-cart-item-desktop {
  width: 160px;

  .empty {
    width: 100%;
    height: 368px;
    border: 1px dashed $color-gray-300;
    background: $color-gray-50;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  .item {
    width: 100%;
    position: relative;

    .tag {
      width: fit-content;
      position: absolute;
      z-index: 1;
      left: 2px;
      top: 2px;

      display: flex;
      padding: 3px 6px;
      justify-content: center;
      align-items: center;
      gap: 3px;

      text-align: center;
      /* Text xs/Regular */
      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */
    }

    .tag_success {
      background-color: $color-success-50;
      color: $color-BNTO-green;
    }

    .tag_alert {
      background-color: $color-error-100;
      color: $color-BNTO-red-alert;
    }

    .image-container {
      border-top: 0.5px solid $color-gray-300;
      border-left: 0.5px solid $color-gray-300;
      border-right: 0.5px solid $color-gray-300;

      width: 100%;
      height: 224px;
      display: flex;

      position: relative;

      .product-img {
        width: 100%;
        height: 100%;
        background: center center no-repeat;
        background-size: cover;

        .cover {
          width: 100%;
          height: 100%;
          background-color: white;
          opacity: 0.5;
        }
      }

      .like {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        position: absolute;
        right: 2px;
        top: 2px;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .info {
      border-left: 0.5px solid $color-gray-300;
      border-right: 0.5px solid $color-gray-300;
      border-bottom: 0.5px solid $color-gray-300;
      padding: 8px;

      .brand {
        overflow: hidden;
        text-overflow: ellipsis;
        /* Text xs/Bold */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 150% */

        // 单行
        word-break: break-all;
        word-wrap: anywhere;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        -webkit-line-clamp: 1;
      }

      .productName {
        overflow: hidden;
        text-overflow: ellipsis;
        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 150% */

        // 单行
        word-break: break-all;
        word-wrap: anywhere;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        -webkit-line-clamp: 1;
      }

      .price {
        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 150% */

        display: flex;
        align-items: center;
        gap: 2px;

        .bold {
          /* Text xs/Bold */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 650;
          line-height: 18px; /* 150% */
        }

        .delete {
          color: $color-gray-500;
          text-decoration: line-through;
          margin-left: 4px;
        }

        .discount {
          /* Text xs/Bold */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 650;
          line-height: 18px; /* 150% */

          color: $color-BNTO-green;
          margin-left: 4px;
        }

        .original {
        }

        .premium {
          width: 18px;
          height: 18px;
          flex-shrink: 0;
        }
      }

      .size {
        overflow: hidden;
        text-overflow: ellipsis;
        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px; /* 150% */
      }
    }

    .btn-container {
      display: flex;

      .btn {
        width: 100%;
        height: 30px;
        border: 0.5px solid $color-BNTO-beige-dark;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        /* Button/small */
        font-family: "Druk Wide Cy";
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px; /* 111.111% */
        text-transform: uppercase;

        cursor: pointer;

        span {
          padding-top: 2px;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }

      .btn-buy {
        background: $color-BNTO-beige-light;
      }
    }
  }

  .alert {
    // 0.5px border
    box-shadow: 0 0 0 0.5px $color-BNTO-red-alert;
  }
}
</style>
