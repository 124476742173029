<script setup>
import {useBaseStore} from "~/stores/base";
import {Loading} from "@element-plus/icons-vue";
import {SKU_STATUS} from "~/utils/constants";

/** emits **/
const emits = defineEmits(["open", "close", "select"])

/** props **/
const props = defineProps({
  productId: {
    default: "",
    required: true
  },
  currentSkuStatus: {
    default: SKU_STATUS.NORMAL,
    required: true
  },
  currentSize: {
    default: "",
    required: true
  },
  options: {
    default: [],
    required: true
  }
})

const isShowOptions = ref(false)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)

/** methods **/
const handleSelectorClick = () => {
  isShowOptions.value = !isShowOptions.value
  if (isShowOptions.value) {
    emits("open")
  } else {
    emits("close")
  }
}
const handleOptionClick = (option) => {
  if (option.inventory === 0) return
  isShowOptions.value = false
  emits("select", option)
}
</script>

<template>
  <div
    class="module-size-selector-mobile"
    :class="{'out-of-stock': currentSkuStatus === SKU_STATUS.OUT_OF_STOCK}"
    v-if="isMobile"
    @click="handleSelectorClick"
  >
    <div class="current-size">{{ currentSize }}</div>
    <div class="icon" :class="{rotate: isShowOptions}">
      <img src="@/assets/img/icon-arrow-down.svg" alt="arrow">
    </div>

    <div class="options" v-if="isShowOptions">
      <div class="options-empty" v-if="options.length === 0">
        <el-icon class="is-loading" color="#292929" size="16">
          <Loading/>
        </el-icon>
      </div>

      <template v-else>
        <div
          class="option"
          :class="{disabled: option.inventory === 0}"
          v-for="option in options"
          @click.stop="handleOptionClick(option)"
        >
          {{ option.value }}
        </div>
      </template>
    </div>
  </div>
  <div
    class="module-size-selector-desktop"
    :class="{'out-of-stock': currentSkuStatus === SKU_STATUS.OUT_OF_STOCK}"
    v-else
    @click="handleSelectorClick"
  >
    <div class="current-size">{{ currentSize }}</div>
    <div class="icon" :class="{rotate: isShowOptions}">
      <img src="@/assets/img/icon-arrow-down.svg" alt="arrow">
    </div>

    <div class="options" v-if="isShowOptions">
      <div class="options-empty" v-if="options.length === 0">
        <el-icon class="is-loading" color="#292929" size="16">
          <Loading/>
        </el-icon>
      </div>

      <template v-else>
        <div
          class="option"
          :class="{disabled: option.inventory === 0}"
          v-for="option in options"
          @click.stop="handleOptionClick(option)"
        >
          {{ option.value }}
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-size-selector-mobile {
  // 0.5px border
  box-shadow: 0 0 0 0.5px $color-gray-300;

  height: 36px;
  padding: 8px 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  margin-top: 8px;
  position: relative;

  cursor: pointer;

  .current-size {
    /* Text xs/Regular */
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */
  }

  .icon {
    display: flex;
    transition: all .2s ease-in-out;
  }

  .rotate {
    transform: rotate(180deg);
  }

  .options {
    width: 144px;
    max-height: 214px;
    overflow-y: auto;
    position: absolute;
    left: -1px;
    top: 36px;
    z-index: 2;
    background: #FFF;
    /* Shadow/lg */
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);

    padding: 4px 0px 0px 4px;
    display: flex;
    flex-direction: column;

    .options-empty {
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .option {
      width: 100%;

      /* Text xs/Regular */
      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */

      cursor: pointer;
      padding: 12px 12px 12px 8px;
      border-bottom: 1px solid $color-gray-200;

      &:hover {
        background-color: $color-gray-100;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .disabled {
      cursor: not-allowed;
      color: $color-gray-300;

      &:hover {
        background-color: white;
      }
    }
  }
}

.module-size-selector-desktop {
  // 0.5px border
  box-shadow: 0 0 0 0.5px $color-gray-300;

  height: 36px;
  padding: 8px 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  margin-top: 8px;
  position: relative;

  cursor: pointer;

  .current-size {
    /* Text xs/Regular */
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */
  }

  .icon {
    display: flex;
    transition: all .2s ease-in-out;
  }

  .rotate {
    transform: rotate(180deg);
  }

  .options {
    width: 144px;
    max-height: 214px;
    overflow-y: auto;
    position: absolute;
    left: -1px;
    top: 36px;
    z-index: 2;
    background: #FFF;
    /* Shadow/lg */
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);

    padding: 4px 0px 0px 4px;
    display: flex;
    flex-direction: column;

    .options-empty {
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .option {
      width: 100%;

      /* Text xs/Regular */
      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */

      cursor: pointer;
      padding: 12px 12px 12px 8px;
      border-bottom: 1px solid $color-gray-200;

      &:hover {
        background-color: $color-gray-100;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .disabled {
      cursor: not-allowed;
      color: $color-gray-300;

      &:hover {
        background-color: white;
      }
    }
  }
}

.out-of-stock {
  // 0.5px border
  box-shadow: 0 0 0 0.5px $color-gray-100;
  background: $color-gray-100;

  .current-size {
    color: $color-gray-400;
  }
}
</style>
