import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function cartEdit({sku, cartItemId}) {
  return omniFetch({
    url: config.v1Path + config.cartEditPath,
    method: "post",
    params: {sku, cartItemId}
  })
}

export function cartRemoveSku({sku}) {
  return omniFetch({
    url: config.v1Path + config.cartRemoveSkuPath,
    method: "post",
    params: {sku}
  })
}
